import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login } from '../store/slices/authSlice'
import Validation from '../utils/Validation';
import '../assets/common.css'
function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [formError, setFormError] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let { authError, isLoading } = useSelector(state => state.auth)

    const handleLogin = (e) => {
        e.preventDefault()
        dispatch(login({ email, password }))
            .unwrap()
            .then(_ => {
                navigate('/home')
            })
            .catch((rejectedValueOrSerializedError) => {
                setFormError(rejectedValueOrSerializedError)
            })
    }
    const getError = (key) => {
        return (formError[key] !== undefined) ? formError[key][0] : null;
    }

    const clearError = (key) => {
        if (key) {
            delete formError[key];
            return;
        }
        setFormError('');
    }

    return (
        <div className='login d-flex align-items-center ' >
            <div className="login-box mx-auto card  ">
                <div className='card-body login-card-body logIn-card'>
                    <div className="login-logo">
                       <img src="../../dist/img/Web/teledoc-text-logo-small.png" alt='tele-doc' width={200}></img>
                    </div>
                    {/* /.login-logo */}
                    <div className="">
                        <div className="">
                            {/* <p className="login-box-msg">Sign in to start your session</p> */}
                            {/* showerror message */}
                            {!!authError ? <span className="text-danger">{authError}</span> : null}

                            <form onSubmit={handleLogin} >
                                <div className="input-group mb-3">
                                    <input className="form-control" type="email" onChange={(e) => { setEmail(e.target.value); clearError('email') }} value={email} placeholder="Email" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope icon-color" />
                                        </div>
                                    </div>
                                </div>
                                <Validation errorText={getError('email')} />

                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" onChange={(e) => { setPassword(e.target.value); clearError('password') }} placeholder="Password" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock icon-color" />
                                        </div>
                                    </div>
                                </div>
                                <Validation errorText={getError('password')} />

                                <div className="border mx-auto">
                                    {/* /.col */}
                                    <div className=" text-center ">
                                        {
                                            isLoading ? (
                                                <button className="btn w-100 logo-color" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button>
                                            )
                                                :
                                                <button type="submit" className="btn logo-color w-100 ">Sign In</button>
                                        }
                                    </div>
                                    {/* /.col */}
                                </div>
                            </form>
                        </div>
                        {/* /.login-card-body */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
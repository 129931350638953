import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { LandingPageFooter } from '../components/LandingPage/LandingPageFooter';
import { LandingPageNavBar } from '../components/LandingPage/LandingPageNavBar';

const WebRoutes = () => {
    return (
            <div>
                <LandingPageNavBar />
                <Outlet />
                <LandingPageFooter />
            </div>
    );
};

export default WebRoutes;

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../LandingPage/web.css'

export const PublicPageNavBar = () => {
  const [optionId, setOptionId] = useState(1)
  // const navbarOption = [
  //   {
  //     id: 1,
  //     option: "HOME",
  //     link: '/'
  //   },
  //   {
  //     id: 2,
  //     option: "PATIENTS",
  //     link: '/patients',
  //   }, {
  //     id: 3,
  //     option: "PROVIDERS",
  //     link: '/providers',
  //   },
  //   {
  //     id: 4,
  //     option: "LOGIN",
  //     link: '/login'
  //   }
  // ]
  document.onreadystatechange = function () {
    let lastScrollPosition = 0;
    const navbar = document.querySelector('.navbar');
    const icon = document.querySelector('.rounded');
    window.addEventListener('scroll', function (e) {
      lastScrollPosition = window.scrollY;
      if (lastScrollPosition > 100) {
        navbar.classList.add('navbar-dark');
        icon.classList.remove('img');
      }
      else {
        navbar.classList.remove('navbar-dark');
        icon.classList.add('img');
      }
    });
  }

  return (
    <section className='mb-5'>
      <nav className="navbar navbar-expand-lg navbar-light bg-light position-top ">
        <div className="container-fluid mx-md-5">
          {/* <a className="navbar-brand" href="#">WEBSITE</a> */}

          <Link to="#" className="nav-link">
            <img src="../../dist/img/Web/teledoc-text-logo-small.png" id='icon' className="rounded img-fluid img"
              alt="..." style={{ width:"14%",transition: "ease-in-out .2s"}} />
          </Link>

        </div>
      </nav>
    </section>
  )
}

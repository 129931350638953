import React, {useState, useEffect} from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useParams} from 'react-router-dom';
import AfterRegisterNote from '../components/AfterRegisterNote';
import {get, put} from '../utils/fetchAPI';

function SetPassword() {
    const {id} = useParams()
    const {accessToken} = useParams()

    const initialState = {
        password: "",
        confirm_password: "",
    };
    const initialStateForErrors = {
        password: '',
        confirm_password: '',
    }
    const [formData, setFormData] = useState(initialState)
    const [formErrors, setFormErrors] = useState(initialStateForErrors)
    // const [stateList, setStateList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState('')
    const [fromInvalidMessage, setFromInvalidMessage] = useState()
    const [showAfterRegisterNote, setShowAfterRegisterNote] = useState(false)

    function handleChange(evt) {
        if (evt.target.name === 'password') {
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
                confirm_password: ''
            });
        } else {
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            })
        }
        validateFields(evt.target.name, evt.target.value)
    }


    function validateFields(fieldName, value) {
        let errors = {...formErrors}
        switch (fieldName) {
            case 'password':
                errors.password = value.length < 6 ? "Required atleast 6 characters!" : "";
                break;
            case 'confirm_password':
                errors.confirm_password = value !== formData.password ? "Password doesn't match!" : "";
                break;
            default:
                break;
        }
        setFormErrors(errors)
    }

    //
    const formValid = ({home, addressLineTwo, doctors, hcp, ...rest}, formErrors) => {
        let isValid = false;
        let isRquired = false;
        isValid = Object.values(formErrors).every(el => el == '' ? true : false)
        isRquired = Object.values(rest).every(el => el != '' ? true : false)

        return isValid && isRquired;
    }

    const setPassword = (e) => {
        e.preventDefault()
        setFromInvalidMessage('')
        let allOk = formValid(formData, formErrors)
        if (!allOk) {
            setFromInvalidMessage('Need to fill required fields!')
        }
        else{
            // now update data
            formData.access = accessToken
            put(`/setNewPassword`, formData)
                .then(res => setShowAfterRegisterNote(true))
                .catch(err => console.log(err))
        }
    }

    const findUser = () => {
        setIsLoading(true)
        setError('')
        get(`/verifyAccess/${id}/${accessToken}`)
            .then(res => {
                console.log(res)
                if (res) {
                    const {_id} = res.data;
                    setFormData({
                        ...formData,
                        _id,
                    });
                }

            })
            .catch(err => setError('Invalid URL!'))
            .finally(() => setIsLoading(false))
    }
    useEffect(() => {
        document.body.classList.add('register-page');
        findUser()
        return () => {
            document.body.classList.remove('register-page');
        };
    }, [id]);

    return (
        <div className="register-box" style={{width: "350px"}}>
            <div className="register-logo">
                <a href="#"><b>Teledoc Telemedicine</b></a>
            </div>
            <div className="card">
                {
                    isLoading ?
                        <button className="btn m-3" type="button" disabled>
                            <span className="spinner-border spinner-border-sm mr-2" role="status"
                                  aria-hidden="true"></span>
                            Loading...
                        </button>
                        :
                        <>
                            {
                                error && !showAfterRegisterNote?
                                    <div className="text-center card-body register-card-body m-3">
                                        <span className="text-danger">{error}</span>
                                    </div>
                                    :
                                    <>
                                        {
                                            !showAfterRegisterNote ?
                                                <div className="card-body p-2 register-card-body m-3">
                                                    <h4 className="login-box-msg">Enter a new password </h4>
                                                    <form onSubmit={setPassword}>
                                                        {!!fromInvalidMessage ?
                                                            <span
                                                                className="text-danger mb-3">{fromInvalidMessage}</span> : null}

                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <label>New Password<span
                                                                    className="text-danger">*</span></label>
                                                                <input type="password" name="password"
                                                                       className={"form-control " + (formErrors.password.length > 0 ? 'is-invalid' : '')}
                                                                       value={formData.password}
                                                                       onChange={handleChange}/>
                                                                {formErrors.password.length > 0 && (
                                                                    <span
                                                                        className="text-danger">{formErrors.password}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <label>Confirm Password<span
                                                                    className="text-danger">*</span></label>
                                                                <input type="password" name="confirm_password"
                                                                       className={"form-control " + (formErrors.confirm_password.length > 0 ? 'is-invalid' : '')}
                                                                       value={formData.confirm_password}
                                                                       onChange={handleChange}/>
                                                                {formErrors.confirm_password.length > 0 && (
                                                                    <span
                                                                        className="text-danger">{formErrors.confirm_password}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            {/* /.col */}
                                                            <div className="col-md-4 offset-md-8">
                                                                <button type="submit"
                                                                        className="btn btn-primary btn-block">Save
                                                                </button>
                                                            </div>
                                                            {/* /.col */}
                                                        </div>
                                                    </form>
                                                </div>
                                                :
                                                <AfterRegisterNote/>
                                        }
                                    </>
                            }

                        </>
                }
            </div>
            {/* /.card */}
        </div>
    )
}

export default SetPassword
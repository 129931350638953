import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWithParams, post, put } from "../../utils/fetchAPI";

const initialState = {
    patients: [],
    invitedPatients: [],
    doctors: [],
    staffs: [],
    pagination: {
        current_page: 1
    },
    isLoading: false,
    isOperationLoading: false,
    error: null
}
export const getPatients = createAsyncThunk(
    'hcp/getPatients',
    async (payload, { getState, rejectWithValue }) => {
        const state = getState()
        const hcpID = state.auth.user?.hcp?._id
        try {
            const response = await getWithParams(`/hcp/${hcpID}/patients`, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const getInvitedPatients = createAsyncThunk(
    'hcp/getInvitedPatients',
    async (payload, { getState, rejectWithValue }) => {
        const state = getState()
        const hcpID = state.auth.user?.hcp?._id
        try {
            const response = await getWithParams(`/hcp/${hcpID}/get-invited-patients`, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const getDoctors = createAsyncThunk(
    'hcp/getDoctors',
    async (payload, { getState, rejectWithValue }) => {
        const state = getState()
        const hcpID = state.auth.user?.hcp?._id
        try {
            const response = await getWithParams(`/hcp/${hcpID}/getDoctors`, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const getStaffs = createAsyncThunk(
    'hcp/getStaffs',
    async (payload, { getState, rejectWithValue }) => {
        const state = getState()
        const hcpID = state.auth.user?.hcp?._id
        try {
            const response = await getWithParams(`/hcp/${hcpID}/getStaffs`, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const createUser = createAsyncThunk(
    'hcp/createUser',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await post('/user', payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const updateUser = createAsyncThunk(
    'hcp/updateUser',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await put(`/user/${payload._id}`, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

const hcpSlice = createSlice({
    name: 'hcp',
    initialState,
    extraReducers: {
        [getPatients.pending]: (state) => {
            state.isLoading = true
            state.error = null
        },
        [getPatients.fulfilled]: (state, action) => {
            state.isLoading = false
            let {users, ...rest} = action.payload
            state.patients = users
            state.pagination = rest
            state.error = null
        },
        [getPatients.rejected]: (state, action) => {
            state.isLoading = false
            state.error = action.payload.message
        },

        [getInvitedPatients.pending]: (state) => {
            state.isLoading = true
            state.error = null
        },
        [getInvitedPatients.fulfilled]: (state, action) => {
            state.isLoading = false
            let {users, ...rest} = action.payload
            state.invitedPatients = users
            state.pagination = rest
            state.error = null
        },
        [getInvitedPatients.rejected]: (state, action) => {
            state.isLoading = false
            state.error = action.payload.message
        },

        [getDoctors.pending]: (state) => {
            state.isLoading = true
            state.error = null
        },
        [getDoctors.fulfilled]: (state, action) => {
            state.isLoading = false
            let {users, ...rest} = action.payload
            state.doctors = users
            state.pagination = rest
            state.error = null
        },
        [getDoctors.rejected]: (state, action) => {
            state.isLoading = false
            state.error = action.payload.message
        },

        [getStaffs.pending]: (state) => {
            state.isLoading = true
            state.error = null
        },
        [getStaffs.fulfilled]: (state, action) => {
            state.isLoading = false
            let {users, ...rest} = action.payload
            state.staffs = users
            state.pagination = rest
            state.error = null
        },
        [getStaffs.rejected]: (state, action) => {
            state.isLoading = false
            state.error = action.payload.message
        },

        [createUser.pending]: (state) => {
            state.isOperationLoading = true
            state.error = null
        },
        [createUser.fulfilled]: (state, action) => {
            state.isOperationLoading = false
            state.error = null
        },
        [createUser.rejected]: (state, action) => {
            state.isOperationLoading = false
            state.error = action.payload.message
        },

        [updateUser.pending]: (state) => {
            state.isOperationLoading = true
            state.error = null
        },
        [updateUser.fulfilled]: (state, action) => {
            state.isOperationLoading = false
            // state.doctors = action.payload
            state.error = null
        },
        [updateUser.rejected]: (state, action) => {
            state.isOperationLoading = false
            state.error = action.payload.message
        },
    }
})

export default hcpSlice.reducer


import React from 'react';
import { Outlet } from 'react-router-dom';
import { LandingPageFooter } from '../components/LandingPage/LandingPageFooter';
import {PublicPageNavBar} from "../components/LandingPage/PublicPageNavBar";

const PublicRoutes = () => {
    return (
            <div>
                <PublicPageNavBar />
                <Outlet />
                <LandingPageFooter />
            </div>
    );
};

export default PublicRoutes;

import React from 'react'

export const Providers = () => {
  return (
    <section className='margin-top-body'>

      <div className='container pt-5'>
        <div className='row mt-5 mb-4 d-flex flex-row-reverse'>

          <div className='col-md-6  d-flex  justify-content-center align-items-center'>
            <img src="../../dist/img/Web/Providers/Doctor-Laptop.png" className="rounded "
              alt="..." style={{ width: "100%" }} />
          </div>
          <div className='col-md-6  d-flex  align-items-center  flex-row-reverse'>
            <div className='text-center'>
              <h3 className='text-center'>Teledoc</h3>
              <h5 className='text-center text-muted '>Built by providers, for providers.Whether you’re in front of a computer or a smartphone, connecting with patients has never been easier.</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5">
        <h3 className='pt-5 text-center'>Enhance the way you practice medicine with Teledoc</h3>
        <div className='row mt-5 pt-5'>

          <div className="col-md-4 col-12  text-center px-2 ">
            <img src="../../dist/img/Web/Icon-3.png" className=" rounded img-fluid"
              alt="..." style={{ width: "10%" }} />
            <h5 className='fw-bolder'>Easy to Use</h5>
            <p className='text-secondary'> Available or Not Available: Single button for Doctors on app and a simple Web Portal for Administrators.</p>
          </div>
          <div className="col-md-4 col-12  text-center px-2">
            <img src="../../dist/img/Web/Icon-1.png" className=" rounded img-fluid"
              alt="..." style={{ width: "10%" }} />
            <h5>Link and click</h5>
            <p className='text-secondary'>
              Patients can connect with their doctors by simply clicking on their personalized link.</p>
          </div>
          <div className="col-md-4 col-12 text-center px-2">
            <img src="../../dist/img/Web/Icon-2.png" className="rounded img-fluid"
              alt="..." style={{ width: "10%" }} />
            <h5>Waiting Rooms</h5>
            <p className='text-secondary'>
              Virtual waiting rooms to seamlessly manage patient flow and assign practitioners accordingly.</p>
          </div>
          <div className="col-md-4 col-12  text-center px-2 pt-5">
            <img src="../../dist/img/Web/Icon-3.png" className=" rounded img-fluid"
              alt="..." style={{ width: "10%" }} />
            <h5 className='fw-bolder'>Here to Help</h5>
            <p className='text-secondary'>
              We provide training, on-boarding, marketing and 24-Hour support for providers, patients and staff. Our customer support is always here for you.</p>
          </div>
          <div className="col-md-4 col-12  text-center px-2 pt-5">
            <img src="../../dist/img/Web/Icon-1.png" className=" rounded img-fluid"
              alt="..." style={{ width: "10%" }} />
            <h5>Secure Visits</h5>
            <p className='text-secondary'>

              With Teledoc your telemedicine visits are HIPAA, HITECH and COPPA compliant secure visits.</p>
          </div>
          <div className="col-md-4 col-12 text-center px-2 pt-5">
            <img src="../../dist/img/Web/Icon-2.png" className="rounded img-fluid"
              alt="..." style={{ width: "10%" }} />
            <h5>Administrative Driven</h5>
            <p className='text-secondary'>
              Tired of videoconferencing apps making you the call taker, scheduler, administrator and biller? We’re videoconferencing and administrator driven.</p>
          </div>

        </div>
      </div>

      <div className='bg mt-5 p-5'>
        <div className='container'>
          <div className='row mt-5 mb-4'>
            <div className='col-md-6  d-flex  justify-content-center align-items-center'>
              <img src="../../dist/img/Web/Providers/revenue-growth.png" className="rounded "
                alt="..." style={{ width: "90%" }} />
            </div>
            <div className='col-md-6  d-flex  align-items-center  justify-content-center'>
              <div>
                {/* <h3 className='text-center'>House Calls for the Modern Practice™</h3> */}
                <h3 className='text-center text-muted mt-2'>Revenue Growth</h3>
                <h6 className='text-center text-muted'>Miscellaneous expenses, scheduling conflicts, time consuming wait rooms and travel hinder patients from having the initial face to face meeting with practitioners. Teledoc has significantly reduced these barriers by widening customer reach and reducing patient no-shows. The surge in patient flow has drastically increased the revenue of our practitioners.</h6>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div className='container pt-5 pb-5'>
        <div className='row mt-5 mb-4 d-flex flex-row-reverse'>
          <div className='col-md-6  d-flex  justify-content-center align-items-center'>
            <img src="../../dist/img/Web/Providers/conversion.jpg" className="rounded "
              alt="..." style={{ width: "80%" }} />
          </div>
          <div className='col-md-6  d-flex  align-items-center  flex-row-reverse'>
            <div className='text-center'>
              <h4 className='text-center'>Improve Conversion Rates</h4>
              <h5 className='text-center text-muted '>  Teledoc has closely worked with practitioners over the years to successfully increase their conversion rates. We have minimized missed and cancelled appointments and fast tracked patient care to procedures, surgeries and product sales.</h5>
            </div>
          </div>
        </div>
      </div>


      <div className='bg mt-5 container rounded mb-5'>
        <div className=" py-5">
          <div className='row'>
            <div className="col-md-12 col-12 text-center  my-auto">
              <div className='text-center'>
                <h5 className='text-secondary text-center w-75 mx-auto'>Book a meeting and find out how Teledoc can transform your practice. Start your telemedicine visit today.</h5>
              </div>
              <div className='text-center mt-5 mb-4'>
                <button className='btn btn-primary px-4'>Book a meeting</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

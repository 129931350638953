import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { get } from '../utils/fetchAPI';
import { Toast } from '../utils/SwalUti';
  
ChartJS.register(ArcElement, Tooltip, Legend);

function Home() {
    const hcpID = useSelector(state => state.auth.user.hcp._id) 
    const [data, setData] = useState(null)

    useEffect(() => {
      get(`/callStatistics/${hcpID}`)
      .then(res => {
        console.log(res.data);
        setData(res.data)
      })
      .catch(res => {
        Toast.fire({
            icon: 'error',
            title: 'Something went wrong!'
        })
      })
    }, [])
    
    return (
        <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <section className="content-header p-2">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h1></h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                             <div className="card-header">
                                <h3 className="card-title">Call Status Breakdown</h3>
                            </div>
                            <div className="card-body">
                                {
                                    data ?
                                    <Pie 
                                        data={data}
                                        height={"250px"}
                                        options={{ maintainAspectRatio: false }} 
                                    />
                                    :
                                    null
                                }
                            </div>
                        </div>
                        {/* /.card */}
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                             <div className="card-header">
                                <h3 className="card-title">Number of Calls This Month</h3>
                            </div>
                            <div className="card-body">
                                <h1>{data?.totalCallOfThisMonth}</h1>
                            </div>
                        </div>
                        {/* /.card */}
                    </div>
                </div>
            </section>
            {/* /.content */}
        </div>
    )
}

export default Home
import React from 'react'
import { calculate } from '../utils/CalculateSecond';
import moment from "moment-timezone";

function MakePDF({pdfData}) {
    const dateTime = moment(pdfData.createdAt).tz('America/New_York').format('MM-DD-YYYY HH:mm');
    return (
    <div style={{ margin: '120px 80px' }} >
      <h4 className="text-center mb-4">Telemedicine Visit Report</h4>
      <h5 className='text-justify'>The purpose of this report is to show that a successful synchronous telemedicine service rendered via a HIPAA Compliant real-time interactive audio and video telecommunications system were rendered using Teledoc app between the below listed provider and patient.</h5>
      <h5>Date of Service:  {dateTime}</h5>
      <h5>Payment Amount: $0</h5>
      <h5>Payor: {pdfData.chargeMethod ? pdfData.chargeMethod : ''}</h5>
      <h5>Duration of Call: {pdfData.duration ? calculate(pdfData.duration) : ''}</h5>
      <h5>Type of evaluation performed:</h5>
      <p className='text-justify ml-4'>Synchronous telemedicine service rendered via a real-time interactive audio and video telecommunications system were performed using Teledoc app.</p>
      <h5>Informed Consent:</h5>
      <p className='text-justify ml-4'>The patient has read and acknowledged the privacy policy and terms of use for Teledoc when signing up for the service and prior to the telemedicine visit. The patient digitally acknowledged that they fully understand its contents including the risks and benefits of the service(s) and that it does not include emergency services.</p>

      <h5>Povider Organization: {pdfData?.hcp?.name ? pdfData.hcp.name : ''}</h5>
      <h5>Povider Name: {pdfData.doctorName ? pdfData.doctorName : ''}</h5>
      <h5>Povider Title: {pdfData?.doctor?.professionalRole ? pdfData.doctor.professionalRole : ''}</h5>
      <h5>Povider Street Address: {pdfData?.doctor?.addressLineOne ? pdfData.doctor.addressLineOne : ''}</h5>
      <h5>Povider City, State, Zip: {pdfData.doctor ? `${pdfData?.doctor?.city}, ${pdfData?.doctor?.state}, ${pdfData?.doctor?.zip}` : ''}</h5>
      <h5>Provider Telephone: {pdfData?.doctor?.mobile ? pdfData?.doctor?.mobile : ''}</h5>
      <br/>
      <h5>Patient Name: {pdfData.patientName ? pdfData.patientName : ''}</h5>
      <h5>Patient Street Address: {pdfData?.patient?.addressLineOne ? pdfData?.patient?.addressLineOne : ''}</h5>
      <h5>Patient City, State, Zip: {pdfData.patient ? `${pdfData?.patient?.city}, ${pdfData?.patient?.state}, ${pdfData?.patient?.zip}` : ''}</h5>
      <h5>Patient Telephone: {pdfData?.patient?.mobile ? pdfData?.patient?.mobile : ''}</h5>
      <br/>
    
      <p className='text-justify'>
      <b>Disclaimer:</b> 
      <br/>
      Type of evaluation performed, Informed consent, if appropriate (In many telemedicine programs, the referring physician/orga- nization retains the original and a copy is sent to the consulting physician/organization), Evaluation results (In many telemed- icine programs, the consulting physician/organization retains the original and a copy is sent to the referring physician/organi- zation), Diagnosis/impression, and Recommendations for further treatment are to be recorded by the rendering provider listed in the report. It is important to ensure that patient registration information needed by the consulting physician/organization is obtained, in addition to information routinely obtained. Retention of telemedical records should be in accordance with state laws or regulations and any reimbursement requirements. Maintenance of telemedical records should ensure that the organization can quickly assemble all components of a patient's record, regardless of their location in the organization. In the absence of policies specifically addressing disclosure of telemedical information, disclosure should be allowed upon receipt of written authorization from the patient or legal representative or in accordance with court order, subpoena, or statute. Informed consent for telemedical encounters should include the names of both the referring physician and the consulting physician, and it should inform the patient that his/her health information will be electronically transmitted. Telemedical records media may be hard copy, video or audiotape, monitor strip, or electronic files. Some states specify acceptable media for health records. Review the appropriate state laws and regulations for any specific requirements. To avoid duplication of information and determine custodianship, identify the responsible holder and owner of the legal telemedicine record.
      </p>
    </div>
  )
}

export default MakePDF
import React from 'react'
import { Link } from 'react-router-dom'
import '../../components/LandingPage/web.css'
export const LandingPage = () => {
  return (
    <section className='margin-top-body'>
      {/* <LandingPageBody /> */}
      <div className='container'>
        <div className='row mt-5 mb-4 d-flex flex-row-reverse'>

          <div className='col-md-6  d-flex justify-content-md-end justify-content-center align-items-center'>
            <img src="../../dist/img/Web/call-and-login.jpg" className="rounded img-fluid"
              alt="..." style={{ width: "75%" }} />
          </div>
          <div className='col-md-6  d-flex  align-items-center'>
            <div>
              <h3 className='text-center'>House Calls for the Modern Practice</h3>
              <h5 className='text-center text-muted'>Medical house calls made easy, convenient and secure.</h5>
              <div className='text-center mt-4 mb-4'>
                <a href='https://apps.apple.com/us/app/teledoc-telemedicine/id1668544359' target={"_blank"} className='m-3' > <img src="../../dist/img/apple_store.jpg" className=" rounded img-fluid"
                  alt="..." style={{ width: "30%", }} /></a>
                <a href='https://play.google.com/store/apps/details?id=teledoc.clinic' target={"_blank"}> <img src="../../dist/img/google_store.jpg" className=" rounded img-fluid"
                  alt="..." style={{ width: "30%", }} /></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className='row mt-5'>

          <div className="col-md-4 col-12 mt-5 text-center px-4 ">
            <img src="../../dist/img/Web/Icon-3.png" className="mt-md-5 rounded img-fluid"
              alt="..." style={{ width: "25%" }} />
            <h5 className='fw-bolder'>Easy</h5>
            <p className='text-secondary'>We provide an easy to use affordable telemedicine to keep the focus on the doctor-patient relationship.</p>
          </div>
          <div className="col-md-4 col-12 mt-5 text-center px-2">
            <img src="../../dist/img/Web/Icon-1.png" className=" rounded img-fluid"
              alt="..." style={{ width: "25%" }} />
            <h5>Secure</h5>
            <p className='text-secondary'>We are HIPAA, HITECH and COPPA compliant telemedicine platform so you don’t have to worry about your privacy and security.</p>
          </div>
          <div className="col-md-4 col-12 mt-5 text-center px-2">
            <img src="../../dist/img/Web/Icon-2.png" className="mt-md-5 rounded img-fluid"
              alt="..." style={{ width: "25%" }} />
            <h5>Convenient</h5>
            <p className='text-secondary'>No more travel, wait rooms and missing work to see your doctor. Get hassle free accessible care at your comfort.</p>
          </div>

        </div>
      </div>
      <div className='bg mt-5'>
        <div className="container py-5">
          <div className='row'>

            <div className="col-md-6 col-12">
              <img src="../../dist/img/Web/team-call.jpg" className="rounded img-fluid"
                alt="..." style={{ width: "90%" }} />
            </div>
            <div className="col-md-6 col-12 text-center d-flex align-items-center my-auto">
              <div>
                <h4>Continuity of care</h4>
                <p className='text-secondary'>  Teledoc delivers effective and high quality sustainable telemedicine solutions to maintain continuity and quality of care. Keep the relationships you’ve established over the years.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container mt-5 py-5'>
        <div className='row mt-5 mb-4 d-flex flex-row-reverse'>

          <div className='col-md-6  d-flex justify-content-md-end justify-content-center align-items-center'>
            <img src="../../dist/img/Web/seamless_medical_visits.png" className="rounded img-fluid"
              alt="..." style={{ width: "100%" }} />
          </div>
          <div className='col-md-6  d-flex  align-items-center mt-5'>
            <div>
              <h1 className='text-center'>Seamless medical visits from anywhere, <span style={{ color: "#33cccc" }}>anytime.</span></h1>
            </div>
          </div>
        </div>
      </div>

      <div className='container mt-5 py-5'>
        <div className='row mb-4'>
          <div className='col-md-6  d-flex justify-content-md-end align-items-center  mt-5'>

            <img src="../../dist/img/Web/benifits.jpg" className="rounded img-fluid"
              alt="..." style={{ width: "90%" }} />
          </div>
          <div className='col-md-6 text-center   d-flex  justify-content-center align-items-center'>
            <div>
              <h3 className='text-center text-secondary'>“5 Benefits of Telemedicine” <br /> For more information</h3>
              {/* <button className='btn btn-primary px-5'>Learn more</button> */}
              <Link to="/5-benefits-of-telemedicine" className="nav-link btn btn-primary px-1 w-50 mx-auto">
                Learn more
              </Link>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

import React, { useState, useEffect, useCallback, useRef } from 'react'
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from 'react-redux';
import { getCalls} from '../store/slices/callSlice';
import debounce from 'lodash/debounce'
import MakePDF from '../components/MakePDF';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { calculate } from '../utils/CalculateSecond';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faDownload, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons'


function Analytics() {
  const dispatch = useDispatch()
  const printRef = useRef()
  const { calls, pagination, isLoading } = useSelector(state => state.call)
  const { current_page, per_page, total } = pagination
  const [length, setLength] = useState(10)
  const [searchQuery, setSearchQuery] = useState('')
  const [pdfData, setPdfData] = useState("test data")
  const [showPdf, setShowPdf] = useState(false)

  const handlePDF = (id) => {
    setPdfData(calls.find(call => call._id === id))
    setShowPdf(true)
  }

  const handleDownloadORPrintPdf = async (prm) => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    if(prm === 'download'){
      pdf.save('Analytics.pdf');
    }
    if(prm === 'print'){
      pdf.autoPrint();  // <<----------print----------- !!
      window.open(pdf.output('bloburl'), '_blank');
    }
  };

  const index = (page = 1) => {
    dispatch(getCalls({ page: page, per_page: length, search: searchQuery }))

  }
  const delayedQuery = useCallback(debounce(index, 500), [searchQuery, length])

  useEffect(() => {
    delayedQuery();
    return delayedQuery.cancel;
  }, [ searchQuery, delayedQuery, length]);

  return (
    <div className="content-wrapper">
      <section className="content pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="float-left">
                    <h2 className="card-title pt-1">Analytics</h2>
                    {
                      showPdf ?
                      <>
                        <button className="ml-4 card-title btn p-0" onClick={(e) => handleDownloadORPrintPdf('download')} >
                          <FontAwesomeIcon icon={faDownload} className='text-green'/> Download PDF
                        </button>
                        <button className="ml-4 card-title btn p-0" onClick={(e) => handleDownloadORPrintPdf('print')} >
                          <FontAwesomeIcon icon={faPrint} className='text-info'/> Print PDF
                        </button>
                        <button className="ml-4 card-title btn p-0" onClick={(e) => {setShowPdf(false)}} >
                          <FontAwesomeIcon icon={faTimes} className='text-red'/> Close PDF
                        </button>
                      </>
                      :
                      ''
                    }
                    <a href='' className="ml-4 card-title">
                      {
                        isLoading ?
                            (
                                <div className="loader"></div>
                            )
                            : ''
                      }
                    </a>
                  </div>
                  <div className="float-right">
                    {
                      !showPdf ?
                      <div className="input-group" >
                        <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="form-control form-control-sm" type="text" placeholder="Search" />
                      </div>
                      :
                      ''
                    }
                  </div>
                </div>
                {/* /.card-header */}

                {
                  !showPdf ? 
                  <>
                    <div className="card-body" style={{ overflowX: 'auto' }} >

                      <table id="example2" className="table table-hover table-sm table-responsive{-sm|-md|-lg|-xl}">
                        <thead>
                          <tr>
                            <th>PATIENT</th>
                            <th>HEALTHCARE PROFESSIONAL</th>
                            <th>DURATION</th>
                            <th>REVENUE</th>
                            <th>STATUS</th>
                            <th>DATE</th>
                            <th className="text-center" >ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!!calls && calls.map((call, index) => (
                            <tr key={index} >
                              <td>{call.patientName}</td>
                              <td>{call.doctorName}</td>
                              <td>{calculate(call.duration)}</td>
                              <td>{call.chargeMethod}</td>
                              <td>{call.status}</td>
                              <td>{new Date(call.createdAt).toLocaleDateString()}</td>
                              <td className="text-center" >
                                <div className="btn-group btn-group-sm" role="group" aria-label="single group">
                                  {
                                    call.status === "Completed" ?
                                    <button type="button" className="btn btn-sm" onClick={() => handlePDF(call._id)} >
                                      <FontAwesomeIcon icon={faFilePdf} style={{color:'red'}} size='lg' />
                                    </button>
                                    :
                                    ''
                                  }
                                </div>
                              </td>
                            </tr>
                          ))
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer">
                      <div className="float-left">
                        <div className="dataTables_info" id="example2_info" role="status" aria-live="polite">
                          {/* <div className="float-left">
                          </div> */}
                          {
                            !!calls ?
                              (
                                <>
                                  <select onChange={(e) => setLength(e.target.value)}>
                                    <option value={10}>10 entries </option>
                                    <option value={20}>20 entries </option>
                                    <option value={50}>50 entries </option>
                                    <option value={100}>100 entries </option>
                                  </select> &nbsp;
                                  Showing {((current_page - 1) * per_page) + 1} to {total > current_page * per_page ? current_page * per_page : total} of {total} entries
                                </>
                              )
                              :
                              null
                          }
                        </div>
                      </div>
                      <div className="float-right">
                        <Pagination
                          activePage={parseInt(current_page)}
                          itemsCountPerPage={parseInt(per_page)}
                          totalItemsCount={parseInt(total)}
                          // pageRangeDisplayed={5}
                          onChange={(current_page) => index(current_page)}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </>
                  :
                    <div className="card-body" ref={printRef}>
                      <MakePDF pdfData={pdfData} />
                    </div>
                }
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  )
}

export default Analytics
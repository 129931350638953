import React from 'react'

export const LandingPageFooter = () => {
  return (
    <section className='fotter-bg mb-0 pb-4'>
      <hr></hr>
      <div className='container'>
        <div className="row">
          <div className="col-md-12 col-12 text-center ">
            <img src="../../dist/img/Web/logo.jpeg" className="rounded img-fluid"
              alt="..." style={{ width: "8%" }} />
            <p className='text-secondary'>Teledoc solves problems plaguing healthcare by providing easy, convenient and secure access to virtual house calls.</p>
          </div>
          <div className="col-md-6 col-12 ">
            <div className='col-md-12'>
          
            </div>
          </div>
        </div>
      </div>
      <div className='text-center'>
        <h5 className='fw-bolder text-secondary'>Copyright ©2023 Teledoc Telemedicine Technologies</h5>
      </div>
    </section>
  )
}

import React from 'react'

const Benefits = () => {
    return (
        <section className='container margin-top-body'>
            <h4 className='' style={{ color: "#043c9c" }}>5 Benefits of Telemedicine</h4>
            <p className='text-secondary'>Telemedicine has grown rapidly over a short period of time and has now become a mainstream means of delivering healthcare. In this day and age every person owns a smart phone and this advantage has made virtual medical visits through communication technology readily accessible. Telemedicine has never been more efficient.​</p>
            <h5 style={{ color: "#043c9c" }} className="mt-3">1. Convenient Access</h5>
            <p className='text-secondary'>Telemedicine gives patients the liberty to schedule real-time consultations and follow ups at the convenience of their time and place. Flexibility to book medical consultations makes doctor visits easily accessible and reduces cancellations and no-shows.</p>
            <h5 style={{ color: "#043c9c" }} className="mt-3">2. Affordable Care</h5>
            <p className='text-secondary'>Patients have the option to have their initial meetings and consultations with their doctors through telemedicine and only visit the doctor’s office when his/her physical presence is required. This helps patients substantially reduce medical expenditure by eliminating costly travel and miscellaneous expenses, while allowing practitioners to fast track patient care..</p>
            <h5 style={{ color: "#043c9c" }} className="mt-3">3. Time Saving</h5>
            <p className='text-secondary'>Telemedicine simplifies doctor visits by requiring patients to enter virtual medical rooms only when the doctor is available. This allows patients to do away with taking a day off from work, making time consuming trips and wasting valuable time waiting to see their doctors. Practitioners are able to reduce cancellations and missed appointments through this practice.</p>
            <h5 style={{ color: "#043c9c" }} className="mt-3">4. Quality Care</h5>
            <p className='text-secondary'>Patients desire better access to healthcare but prioritizing it becomes difficult due to lack of convenience and accessibility. The readily available aspect of telemedicine helps reduce these gaps between the patient and the practitioner to maintain sustainable quality of care.</p>
            <h5 style={{ color: "#043c9c" }} className="mt-3">5. Support for Caregivers</h5>
            <p className='text-secondary'>The responsibilities of caregivers are a huge task to shoulder and overwhelming at times. By using telemedicine caregivers can minimize stressful unnecessary visits to the hospital for routine checkup, follow up, prescription refills and consultation that can be easily carried out from home. Doctors are able to perform follow ups, conduct real time vital checks and offer recommendation through telemedicine.</p>

            <div className='bg mt-5 container rounded'>
                <div className=" py-5">
                    <div className='row'>
                        <div className="col-md-12 col-12 text-center  my-auto">
                            <div>
                                <h4 className='text-secondary w-50 text-center mx-auto'>Book your telemedicine visit with <span style={{ color: "#043c9c" }}>Teledoc</span>. We provide easy, convenient and secure medical visits.</h4>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Benefits

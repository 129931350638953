import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../LandingPage/web.css'

export const LandingPageNavBar = () => {
  const [optionId, setOptionId] = useState(1)
  const navbarOption = [
    {
      id: 1,
      option: "HOME",
      link: '/'
    },
    {
      id: 2,
      option: "PATIENTS",
      link: '/patients',
    }, {
      id: 3,
      option: "PROVIDERS",
      link: '/providers',
    },
    {
      id: 4,
      option: "LOGIN",
      link: '/login'
    }
  ]
  document.onreadystatechange = function () {
    let lastScrollPosition = 0;
    const navbar = document.querySelector('.navbar');
    const icon = document.querySelector('.rounded');
    window.addEventListener('scroll', function (e) {
      lastScrollPosition = window.scrollY;
      if (lastScrollPosition > 100) {
        navbar.classList.add('navbar-dark');
        icon.classList.remove('img');
      }
      else {
        navbar.classList.remove('navbar-dark');
        icon.classList.add('img');
      }
    });
  }
  
const setOptionIdFun = (id) => {
  setOptionId(id)
  const nav = document.getElementById('navbarSupportedContent')
  nav.classList.add('show')
  nav.classList.remove('show')
  console.log(nav?.classList)
}
  return (
    <section className='mb-5'>
      <nav className="navbar navbar-expand-lg navbar-light bg-light position-top ">
        <div className="container-fluid mx-md-5">
          {/* <a className="navbar-brand" href="#">WEBSITE</a> */}

          {/* <div className='d-flex'>
           \<Link to="/" className="nav-link">
              <img src="../../dist/img/Web/teledoc-text-logo-small.png"  id='icon' className="rounded img-fluid img nav-logo"
                alt="..."/>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div> */}

          <Link to="/" className="nav-link p-0 m-0">
              <img src="../../dist/img/Web/teledoc-text-logo-small.png"  id='icon' className="rounded img-fluid  nav-logo"
                alt="..."/>
            </Link>
          {/* <a className="navbar-brand" href="#">Navbar</a> */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse d-md-flex justify-content-end align-items-center" id="navbarSupportedContent">
           
              <ul className="navbar-nav  me-auto mb-2 mb-lg-0">
                {
                  navbarOption.map((option, index) => {
                    return (
                      <li className={`nav-item`} key={index}>
                        <Link  onClick={() => setOptionIdFun(option?.id)
                        } to={option.link} className={`nav-link ${optionId === option?.id ? "nav-option-active-color" : "nav-option-color"}`}>
                          <p className=''>
                            {option.option}
                          </p>
                        </Link>
                      </li>

                    )
                  })
                }
              </ul>
        
          </div>
        </div>
      </nav>
    </section>
  )
}

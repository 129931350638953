import React from 'react'

export const Patients = () => {
  return (
    <section className='margin-top-body'>
      {/* <LandingPageBody /> */}
      <div className='container'>
        <h2 className='text-center pt-5'>It’s free and easy to get started with Teledoc</h2>
        <div className='row mt-5 mb-4'>
          <div className='col-md-6  d-flex  justify-content-center align-items-center'>
            <img src="../../dist/img/Web/Patients/mobile-login-page.png" className="rounded "
              alt="..." style={{ width: "50%" }} />
          </div>
          <div className='col-md-6  d-flex  align-items-center'>
            <div>
              {/* <h3 className='text-center'>House Calls for the Modern Practice™</h3> */}
              <h3 className='text-center text-muted mt-2'>Download the Teledoc app for free</h3>
              <div className='text-center mt-4 mb-4'>
                <a href='https://apps.apple.com/us/app/teledoc-telemedicine/id1668544359' target={"_blank"} className='m-3'> <img src="../../dist/img/apple_store.jpg" className=" rounded img-fluid"
                  alt="app-store" style={{ width: "30%", }} /></a>
                <a href='https://play.google.com/store/apps/details?id=teledoc.clinic' target={"_blank"}> <img src="../../dist/img/google_store.jpg" className=" rounded img-fluid"
                  alt="google-paystore" style={{ width: "30%", }} /></a>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className='container'>
        <div className='row mt-5 mb-4 d-flex flex-row-reverse'>
         
          <div className='col-md-6  d-flex  justify-content-center align-items-center'>
            <img src="../../dist/img/Web/Patients/mobile-doctor-list.png" className="rounded "
              alt="..." style={{ width: "50%" }} />
          </div>
          <div className='col-md-6  d-flex  align-items-center  flex-row-reverse'>
            <div className='text-center'>
              {/* <h3 className='text-center'>House Calls for the Modern Practice™</h3> */}
              <h3 className='text-center text-muted '>Select the doctor you want to see</h3>
            </div>
          </div>

        </div>
      </div>

      <div className='container'>
        <div className='row mt-5 mb-4'>
          <div className='col-md-6  d-flex  justify-content-center align-items-center'>
            <img src="../../dist/img/Web/Patients/doctor-video-call.jpg" className="rounded "
              alt="..." style={{ width: "50%" }} />
          </div>
          <div className='col-md-6  d-flex  align-items-center  justify-content-center'>
            <div>
              {/* <h3 className='text-center'>House Calls for the Modern Practice™</h3> */}
              <h3 className='text-center text-muted py-2'>Start your consultation</h3>
            </div>
          </div>

        </div>
      </div>

      <div className="container pt-5">
        <div className='row mt-5 pt-5'>

          <div className="col-md-4 col-12  text-center px-2 ">
            <img src="../../dist/img/Web/Icon-3.png" className=" rounded img-fluid"
              alt="..." style={{ width: "25%" }} />
            <h5 className='fw-bolder'>At your comfort</h5>
            <p className='text-secondary'>Teledoc lets you connect with your doctor from the comfort of your home and eliminate unecessary costs.</p>
          </div>
          <div className="col-md-4 col-12  text-center px-2">
            <img src="../../dist/img/Web/Icon-1.png" className=" rounded img-fluid"
              alt="..." style={{ width: "25%" }} />
            <h5>Easily accessible</h5>
            <p className='text-secondary'>Access quality healthcare with our easy to use Teledoc app. Receive the care you deserve.</p>
          </div>
          <div className="col-md-4 col-12 text-center px-2">
            <img src="../../dist/img/Web/Icon-2.png" className="rounded img-fluid"
              alt="..." style={{ width: "25%" }} />
            <h5>We've got your back</h5>
            <p className='text-secondary'>Don’t compromise on your security, get HIPAA, HITECH and COPPA compliant telemedicine visits with Teledoc.</p>
          </div>

        </div>
      </div>
      <div className='bg mt-5 container rounded'>
        <div className=" py-5">
          <div className='row'>
            <div className="col-md-12 col-12 text-center  my-auto">
              <div>
                <h4 className='text-secondary'>Get trusted quality healthcare on your terms with Teledoc. Download the Teledoc app today.</h4>
              </div>
              <div className='text-center mt-5 mb-4'>
                <a href='https://apps.apple.com/us/app/teledoc-telemedicine/id1668544359' target={"_blank"} className='m-3'> <img src="../../dist/img/apple_store.jpg" className=" rounded img-fluid"
                  alt="app-store" style={{ width: "15%", }} /></a>
                <a href='https://play.google.com/store/apps/details?id=teledoc.clinic' target={"_blank"}> <img src="../../dist/img/google_store.jpg" className=" rounded img-fluid"
                  alt="google-paystore" style={{ width: "15%", }} /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

import React from 'react'

function AppFooter() {
    return (
        <footer className="main-footer">
            <strong>Copyright © 2023 <a href="#">Teledoc</a>.</strong> All rights reserved.
        </footer>
    )
}

export default AppFooter
import React from 'react'

function AfterRegisterNote() {
  return (
    <div className="text-center card-body register-card-body m-3">
        <h4>Great to have you onboard!</h4>
        <br/>
        <h5>Click the link below that applies to your smartphone, and begin reaching your doctor with real-time video visits.</h5>
        <br/>
        <p>
            <a href="https://play.google.com/store/apps/details?id=teledoc.clinic" className='p-2 mt-1' >
                <img src="../../dist/img/google_store.jpg" alt="google store" style={{ width: "150px"}} />
            </a>
            <br/>
            <br/>
            <a href="https://apps.apple.com/us/app/teledoc-telemedicine/id1668544359" className='p-2' >
                <img src="../../dist/img/apple_store.jpg" alt="apple store" style={{ width: "150px"}} />
            </a>
        </p>
        <br/>
        <h5>You can also check your email for verification and steps on how to start using Teledoc.</h5>
    </div>
  )
}

export default AfterRegisterNote
import {initializeApp} from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDKig8KFFgkgF7-I7orgPEWh5YdzK6u_rU",
    authDomain: "teledoc-telemedicine.firebaseapp.com",
    projectId: "teledoc-telemedicine",
    storageBucket: "teledoc-telemedicine.appspot.com",
    messagingSenderId: "995508673688",
    appId: "1:995508673688:web:cda0c5c6c476dfd811c4ed",
    measurementId: "G-KLDSW7D84Z"
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp
